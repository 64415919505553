export enum CentralisationInsurersEnum {
  AGRISANO = 'AGRISANO',
  ALLIANZ = 'ALLIANZ',
  ANIMALIA = 'ANIMALIA',
  APPENZELLER = 'APPENZELLER',
  AQUILANA = 'AQUILANA',
  ASSISTA = 'ASSISTA',
  ASSURA = 'ASSURA',
  ATUPRI = 'ATUPRI',
  AXA = 'AXA',
  AXA_ARAG = 'AXA_ARAG',
  BALOISE = 'BALOISE',
  CAP = 'CAP',
  CONCORDIA = 'CONCORDIA',
  COOP = 'COOP',
  CSS = 'CSS',
  DEXTRA = 'DEXTRA',
  EGK = 'EGK',
  ELVIA = 'ELVIA',
  EMILIA = 'EMILIA',
  EMMENTAL = 'EMMENTAL',
  EPONA = 'EPONA',
  EUROP_ASSISTANCE = 'EUROP_ASSISTANCE',
  FIRSTCAUTION = 'FIRSTCAUTION',
  FORTUNA = 'FORTUNA',
  GENERALI = 'GENERALI',
  GENOSSENSCHAFT = 'GENOSSENSCHAFT',
  GENOSSENSCHAFT_SLKK = 'GENOSSENSCHAFT_SLKK',
  GROUPE_MUTUEL = 'GROUPE_MUTUEL',
  GVB = 'GVB',
  HELSANA = 'HELSANA',
  HELVETIA = 'HELVETIA',
  IMPERIO = 'IMPERIO',
  INFRASSURE = 'INFRASSURE',
  INNOVA = 'INNOVA',
  KPT = 'KPT',
  K_TIPP = 'K_TIPP',
  LA_MOBILIERE = 'LA_MOBILIERE',
  LIMMAT = 'LIMMAT',
  LUZEMER_HINTERLAND = 'LUZEMER_HINTERLAND',
  OKK = 'OKK',
  ORION = 'ORION',
  PALLADIO = 'PALLADIO',
  PAX = 'PAX',
  POST_FINANCES = 'POST_FINANCES',
  PROTEKTA = 'PROTEKTA',
  RETRAITES_POPULAIRES = 'RETRAITES_POPULAIRES',
  RHENUSANA = 'RHENUSANA',
  SANITAS = 'SANITAS',
  SAVANALS = 'SAVANALS',
  SLLK = 'SLLK',
  STEFFISBURG = 'STEFFISBURG',
  SWISSCAUTION = 'SWISSCAUTION',
  SCHWEIZERISCHE_HAGEL = 'SCHWEIZERISCHE_HAGEL',
  SIMPEGO = 'SIMPEGO',
  SMARTCAUTION = 'SMARTCAUTION',
  SMILE = 'SMILE',
  SODALIS = 'SODALIS',
  STIFTUNG_KRANKENKASSE_WADENSWIL = 'STIFTUNG_KRANKENKASSE_WADENSWIL',
  SUMISWALDER = 'SUMISWALDER',
  SWICA = 'SWICA',
  SWISS_LIFE = 'SWISS_LIFE',
  SWISSGARANTA = 'SWISSGARANTA',
  SYMPANY = 'SYMPANY',
  TAS_TCS = 'TAS_TCS',
  TSM = 'TSM',
  VAUDOISE = 'VAUDOISE',
  VERSICHERUNG_DER_SCHWEIZER_ARZTE_GENOSSENCHAFT = 'VERSICHERUNG_DER_SCHWEIZER_ARZTE_GENOSSENCHAFT',
  VISANA = 'VISANA',
  VITA_SURSELVA = 'VITA_SURSELVA',
  VZ = 'VZ',
  YOUPLUS = 'YOUPLUS',
  ZURICH_ASSURANCE = 'ZURICH_ASSURANCE',
  WADENSWILL_KK = 'WADENSWILL_KK',
  WAU_MIAU = 'WAU_MIAU',
}

export const getCentralisationInsurerLabel = (
  value: CentralisationInsurersEnum | string,
): string => {
  switch (value) {
    case CentralisationInsurersEnum.AGRISANO:
      return $localize`Agrisano`;
    case CentralisationInsurersEnum.ALLIANZ:
      return $localize`Allianz`;
    case CentralisationInsurersEnum.ANIMALIA:
      return $localize`Animalia`;
    case CentralisationInsurersEnum.APPENZELLER:
      return $localize`Appenzeller`;
    case CentralisationInsurersEnum.AQUILANA:
      return $localize`Aquilana`;
    case CentralisationInsurersEnum.ASSISTA:
      return $localize`Assista Protection juridique SA`;
    case CentralisationInsurersEnum.ASSURA:
      return $localize`Assura SA`;
    case CentralisationInsurersEnum.ATUPRI:
      return $localize`Atupri`;
    case CentralisationInsurersEnum.AXA:
      return $localize`AXA`;
    case CentralisationInsurersEnum.AXA_ARAG:
      return $localize`AXA-ARAG`;
    case CentralisationInsurersEnum.BALOISE:
      return $localize`Baloise assurance`;
    case CentralisationInsurersEnum.CAP:
      return $localize`CAP`;
    case CentralisationInsurersEnum.CONCORDIA:
      return $localize`CONCORDIA`;
    case CentralisationInsurersEnum.COOP:
      return $localize`Coop`;
    case CentralisationInsurersEnum.CSS:
      return $localize`CSS assurance`;
    case CentralisationInsurersEnum.DEXTRA:
      return $localize`Dextra`;
    case CentralisationInsurersEnum.EGK:
      return $localize`EGK`;
    case CentralisationInsurersEnum.ELVIA:
      return $localize`Elvia`;
    case CentralisationInsurersEnum.EMILIA:
      return $localize`Emilia`;
    case CentralisationInsurersEnum.EMMENTAL:
      return $localize`Emmental`;
    case CentralisationInsurersEnum.EPONA:
      return $localize`EPONA`;
    case CentralisationInsurersEnum.EUROP_ASSISTANCE:
      return $localize`Europ Assistance`;
    case CentralisationInsurersEnum.FIRSTCAUTION:
      return $localize`Firstcaution`;
    case CentralisationInsurersEnum.FORTUNA:
      return $localize`Fortuna`;
    case CentralisationInsurersEnum.GENERALI:
      return $localize`Generali`;
    case CentralisationInsurersEnum.GENOSSENSCHAFT:
      return $localize`Genossenschaft`;
    case CentralisationInsurersEnum.GENOSSENSCHAFT_SLKK:
      return $localize`Genossenschaft SLKK`;
    case CentralisationInsurersEnum.GROUPE_MUTUEL:
      return $localize`Groupe Mutuel`;
    case CentralisationInsurersEnum.GVB:
      return $localize`GVB`;
    case CentralisationInsurersEnum.HELSANA:
      return $localize`Helsana`;
    case CentralisationInsurersEnum.HELVETIA:
      return $localize`Helvetia assurance`;
    case CentralisationInsurersEnum.IMPERIO:
      return $localize`Imperio`;
    case CentralisationInsurersEnum.INFRASSURE:
      return $localize`Infrassure`;
    case CentralisationInsurersEnum.INNOVA:
      return $localize`Innova`;
    case CentralisationInsurersEnum.KPT:
      return $localize`KPT`;
    case CentralisationInsurersEnum.LUZEMER_HINTERLAND:
      return $localize`Luzemer Hinterland`;
    case CentralisationInsurersEnum.K_TIPP:
      return $localize`K-Tipp`;
    case CentralisationInsurersEnum.LIMMAT:
      return $localize`Limmat`;
    case CentralisationInsurersEnum.OKK:
      return $localize`ÖKK`;
    case CentralisationInsurersEnum.ORION:
      return $localize`Orion`;
    case CentralisationInsurersEnum.PALLADIO:
      return $localize`Palladio`;
    case CentralisationInsurersEnum.PAX:
      return $localize`Pax`;
    case CentralisationInsurersEnum.PROTEKTA:
      return $localize`Protekta`;
    case CentralisationInsurersEnum.POST_FINANCES:
      return $localize`Post Finances`;
    case CentralisationInsurersEnum.RETRAITES_POPULAIRES:
      return $localize`Retraites Populaires`;
    case CentralisationInsurersEnum.RHENUSANA:
      return $localize`Rhenusana`;
    case CentralisationInsurersEnum.SANITAS:
      return $localize`Sanitas`;
    case CentralisationInsurersEnum.SWISSCAUTION:
      return $localize`SwissCaution`;
    case CentralisationInsurersEnum.SCHWEIZERISCHE_HAGEL:
      return $localize`Schweizerische Hagel`;
    case CentralisationInsurersEnum.LA_MOBILIERE:
      return $localize`La Mobilière`;
    case CentralisationInsurersEnum.SIMPEGO:
      return $localize`Simpego`;
    case CentralisationInsurersEnum.SMARTCAUTION:
      return $localize`SmartCaution`;
    case CentralisationInsurersEnum.SAVANALS:
      return $localize`Savanals`;
    case CentralisationInsurersEnum.SLLK:
      return $localize`SLLK`;
    case CentralisationInsurersEnum.STEFFISBURG:
      return $localize`Steffisburg`;
    case CentralisationInsurersEnum.SODALIS:
      return $localize`Sodalis`;
    case CentralisationInsurersEnum.STIFTUNG_KRANKENKASSE_WADENSWIL:
      return $localize`Stiftung Krankenkasse Wädenswil`;
    case CentralisationInsurersEnum.SUMISWALDER:
      return $localize`Sumiswalder`;
    case CentralisationInsurersEnum.SWICA:
      return $localize`Swica`;
    case CentralisationInsurersEnum.SWISS_LIFE:
      return $localize`Swiss Life AG`;
    case CentralisationInsurersEnum.SWISSGARANTA:
      return $localize`Swissgaranta`;
    case CentralisationInsurersEnum.SYMPANY:
      return $localize`Sympany`;
    case CentralisationInsurersEnum.SMILE:
      return $localize`Smile`;
    case CentralisationInsurersEnum.TAS_TCS:
      return $localize`TAS / TCS`;
    case CentralisationInsurersEnum.TSM:
      return $localize`TSM`;
    case CentralisationInsurersEnum.VAUDOISE:
      return $localize`Vaudoise`;
    case CentralisationInsurersEnum.VERSICHERUNG_DER_SCHWEIZER_ARZTE_GENOSSENCHAFT:
      return $localize`Versicherung der Schweizer Ärzte Genossenschaft`;
    case CentralisationInsurersEnum.VISANA:
      return $localize`Visana`;
    case CentralisationInsurersEnum.VITA_SURSELVA:
      return $localize`Vita Surselva`;
    case CentralisationInsurersEnum.VZ:
      return $localize`VZ`;
    case CentralisationInsurersEnum.YOUPLUS:
      return $localize`Youplus`;
    case CentralisationInsurersEnum.ZURICH_ASSURANCE:
      return $localize`Zurich Assurance`;
    case CentralisationInsurersEnum.WADENSWILL_KK:
      return $localize`Wadenswill KK`;
    case CentralisationInsurersEnum.WAU_MIAU:
      return $localize`Wau Miau`;
    default:
      return value;
  }
};
