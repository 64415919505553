import { APP_BASE_HREF, CommonModule, PlatformLocation } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatChipsModule } from '@angular/material/chips';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-gg';
import { NgxOtpInputModule } from 'ngx-otp-input';

import { AccountCheckComponent } from './components/account-check/account-check.component';
import { AccountCheckV2Component } from './components/account-check-v2/account-check-v2.component';
import { AlternateCollapsiblePanelComponent } from './components/alternate-collapsible-panel/alternate-collapsible-panel.component';
import { AnimatedBannerComponent } from './components/animated-banner/animated-banner.component';
import { BooleanChoiceComponent } from './components/boolean-choice/boolean-choice.component';
import { CalendlyInlineEmbedComponent } from './components/calendly/calendly-inline-embed/calendly-inline-embed.component';
import { CalendlyPopupEmbedComponent } from './components/calendly/calendly-popup-embed/calendly-popup-embed.component';
import { CardComponent } from './components/card/card.component';
import { CollapsibleTabComponent } from './components/collapsible-tab/collapsible-tab.component';
import { CompanyScrollComponent } from './components/company-scroll/company-scroll.componant';
import { ContactModalComponent } from './components/contact-modal/contact-modal.component';
import { ContractSignatureComponent } from './components/contract-signature/contract-signature.component';
import { ContractSignatureV2Component } from './components/contract-signature-v2/contract-signature-v2.component';
import { ContractSignatureV3Component } from './components/contract-signature-v3/contract-signature-v3.component';
import { CredibilitySectionComponent } from './components/credibility-section/credibility-section.componant';
import { CustomDateComponent } from './components/custom-date/custom-date.component';
import { DocumentUploadFormComponent } from './components/document-upload-form/document-upload-form.component';
import { DocumentUploadFormV2Component } from './components/document-upload-form-v2/document-upload-form-v2.component';
import { DrawerModalComponent } from './components/drawer-modal/drawer-modal.component';
import { EndScreenComponent } from './components/end-screen/end-screen.component';
import { EndScreenV2Component } from './components/end-screen-v2/end-screen-v2.component';
import { FamilyMemberSelectComponent } from './components/family-member-select/family-member-select.component';
import { FamilyMemberSelectV2Component } from './components/family-member-select-v2/family-member-select-v2.component';
import { FilterSubmitButtonsComponent } from './components/filter-submit-buttons/filter-submit-buttons.component';
import { FooterComponent } from './components/footer/footer.component';
import { FormButtonsComponent } from './components/form-buttons/form-buttons.component';
import { FormWrapperComponent } from './components/form-wrapper/form-wrapper.component';
import { FormWrapperV2Component } from './components/form-wrapper-v2/form-wrapper-v2.component';
import { GoogleAutocompleteComponent } from './components/google-autocomplete/google-autocomplete.component';
import { HelpTextComponent } from './components/help-text/help-text.component';
import { HelpTextV2Component } from './components/help-text-v2/help-text-v2.component';
import { HorizontalSeparatorComponent } from './components/horizontal-separator/horizontal-separator.component';
import { InfoDisplayerComponent } from './components/info-displayer/info-displayer.component';
import { InfoTooltipComponent } from './components/info-tooltip/info-tooltip.component';
import { InsurancesListComponent } from './components/insurances-list/insurances-list.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';
import { LinearStepperComponent } from './components/linear-stepper/linear-stepper.component';
import { ListAdvantagesComponent } from './components/list-advantages/list-advantages.component';
import { LoadingOffersOfflineScreenComponent } from './components/loading-offers-offline-screen/loading-offers-offline-screen.component';
import { LoadingOffersScreenComponent } from './components/loading-offers-screen/loading-offers-screen.componant';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { LoginComponent } from './components/login/login.component';
import { LoginV2Component } from './components/login-v2/login-v2.component';
import { LtyAdviceComponent } from './components/lty-advice/lty-advice.component';
import { MultiStepperComponent } from './components/multi-stepper/multi-stepper.component';
import { NumberFlowComponent } from './components/number-flow/number-flow.component';
import { NumberInputComponent } from './components/number-input/number-input.component';
import { NumberInputV2Component } from './components/number-input-v2/number-input-v2.component';
import { OffersSearchComponent } from './components/offers-search/offers-search.component';
import { OffersSearchV2Component } from './components/offers-search-v2/offers-search-v2.component';
import { AcceptOfferModalComponent } from './components/offers-view/accept-offer-modal/accept-offer-modal.component';
import { OffersViewComponent } from './components/offers-view/offers-view.component';
import { RejectOfferModalComponent } from './components/offers-view/reject-offer-modal/reject-offer-modal.component';
import { OtpVerifyComponent } from './components/otp-verify/otp-verify.component';
import { OtpVerifyV2Component } from './components/otp-verify-v2/otp-verify-v2.component';
import { PasswordCriteriaComponent } from './components/password-criteria/password-criteria.component';
import { PdfViewerModalComponent } from './components/pdf-viewer-modal/pdf-viewer-modal.component';
import { PoliciesComponent } from './components/policies/policies.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { RegisterComponent } from './components/register/register.component';
import { RegisterV2Component } from './components/register-v2/register-v2.component';
import { ReviewModalComponent } from './components/review-modal/review-modal.component';
import { SelectComponent } from './components/select/select.component';
import { SelectV2Component } from './components/select-v2/select-v2.component';
import { SharedCancellationComponent } from './components/shared-cancellation/shared-cancellation.component';
import { SharedCancellationV2Component } from './components/shared-cancellation-v2/shared-cancellation-v2.component';
import { SimpleDocumentUploadComponent } from './components/simple-document-upload/simple-document-upload.component';
import { SliderComponent } from './components/slider/slider.component';
import { SliderV2Component } from './components/slider-v2/slider-v2.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { StackedCardsComponent } from './components/stacked-cards/stacked-cards.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { AnimalFlowSummaryComponent } from './components/summaries/animal-flow-summary/animal-flow-summary.component';
import { BuildingFlowSummaryComponent } from './components/summaries/building-flow-summary/building-flow-summary.component';
import { CentralisationSummaryComponent } from './components/summaries/centralisation-summary/centralisation-summary.component';
import { ClaimSummaryComponent } from './components/summaries/claim-summary/claim-summary.component';
import { ConstructionFlowSummaryComponent } from './components/summaries/construction-flow-summary/construction-flow-summary.component';
import { CurrentInsurerComponent } from './components/summaries/health-flow-summary/current-insurer/current-insurer.component';
import { DoctorComponent } from './components/summaries/health-flow-summary/doctor/doctor.component';
import { HealthFlowSummaryComponent } from './components/summaries/health-flow-summary/health-flow-summary.component';
import { HealthInfoComponent } from './components/summaries/health-flow-summary/health-info/health-info.component';
import { PersonalInfoComponent } from './components/summaries/health-flow-summary/personal-info/personal-info.component';
import { HouseholdFlowSummaryComponent } from './components/summaries/household-flow-summary/household-flow-summary.component';
import { LegalProtectionFlowSummaryComponent } from './components/summaries/legal-protection-flow-summary/legal-protection-flow-summary.component';
import { OfferDisplayComponent } from './components/summaries/offer-display/offer-display.component';
import { SummarySelectionComponent } from './components/summaries/summary-selection/summary-selection.component';
import { ThirdPillarFlowSummaryComponent } from './components/summaries/third-pillar-flow-summary/third-pillar-flow-summary.component';
import { TravelFlowSummaryComponent } from './components/summaries/travel-flow-summary/travel-flow-summary.component';
import { ValuableObjectsFlowSummaryComponent } from './components/summaries/valuable-objects-flow-summary/valuable-objects-flow-summary.component';
import { VehicleFlowSummaryComponent } from './components/summaries/vehicle-flow-summary/vehicle-flow-summary.component';
import { ToggleComponent } from './components/toggle/toggle.component';
import { TopBarComponent } from './components/top-bar/top-bar.component';
import { UploadFileComponent } from './components/upload-file/upload-file.component';
import { DateLimitDirective } from './directives/date-limit.directive';
import { IconsModule } from './icons/icons.module';
import { SignIconComponent } from './icons/sign-icon.component';
import { KeysPipe } from './pipes/keys.pipe';
import { FormatCurrencyPipe } from './pipes/replace-spaces.pipe';

export function getBaseHref(platformLocation: PlatformLocation): string {
  return platformLocation.getBaseHrefFromDOM();
}

@NgModule({
  declarations: [
    AcceptOfferModalComponent,
    AccountCheckComponent,
    AccountCheckV2Component,
    AlternateCollapsiblePanelComponent,
    AnimalFlowSummaryComponent,
    AnimatedBannerComponent,
    BooleanChoiceComponent,
    BuildingFlowSummaryComponent,
    CalendlyInlineEmbedComponent,
    CalendlyPopupEmbedComponent,
    CardComponent,
    CentralisationSummaryComponent,
    ClaimSummaryComponent,
    CollapsibleTabComponent,
    CompanyScrollComponent,
    ConstructionFlowSummaryComponent,
    ContactModalComponent,
    ContractSignatureComponent,
    ContractSignatureV2Component,
    ContractSignatureV3Component,
    CredibilitySectionComponent,
    CurrentInsurerComponent,
    CustomDateComponent,
    DateLimitDirective,
    DoctorComponent,
    DocumentUploadFormComponent,
    DocumentUploadFormV2Component,
    DrawerModalComponent,
    EndScreenComponent,
    EndScreenV2Component,
    FamilyMemberSelectComponent,
    FamilyMemberSelectV2Component,
    FilterSubmitButtonsComponent,
    FooterComponent,
    FormatCurrencyPipe,
    FormButtonsComponent,
    FormWrapperComponent,
    FormWrapperV2Component,
    GoogleAutocompleteComponent,
    HealthFlowSummaryComponent,
    HealthInfoComponent,
    HelpTextComponent,
    HelpTextV2Component,
    HorizontalSeparatorComponent,
    HouseholdFlowSummaryComponent,
    InfoDisplayerComponent,
    InfoTooltipComponent,
    InsurancesListComponent,
    KeysPipe,
    LanguageSwitcherComponent,
    LegalProtectionFlowSummaryComponent,
    LinearStepperComponent,
    ListAdvantagesComponent,
    LoadingOffersOfflineScreenComponent,
    LoadingOffersScreenComponent,
    LoadingScreenComponent,
    LoginComponent,
    LoginV2Component,
    LtyAdviceComponent,
    MultiStepperComponent,
    NumberFlowComponent,
    NumberInputComponent,
    NumberInputV2Component,
    OfferDisplayComponent,
    OffersSearchComponent,
    OffersSearchV2Component,
    OffersViewComponent,
    OtpVerifyComponent,
    OtpVerifyV2Component,
    PasswordCriteriaComponent,
    PdfViewerModalComponent,
    PersonalInfoComponent,
    PoliciesComponent,
    ProgressBarComponent,
    RegisterComponent,
    RegisterV2Component,
    RejectOfferModalComponent,
    ReviewModalComponent,
    SelectComponent,
    SelectV2Component,
    SharedCancellationComponent,
    SharedCancellationV2Component,
    SignIconComponent,
    SimpleDocumentUploadComponent,
    SliderComponent,
    SliderV2Component,
    SpinnerComponent,
    StackedCardsComponent,
    StepperComponent,
    SummarySelectionComponent,
    ThirdPillarFlowSummaryComponent,
    ToggleComponent,
    TopBarComponent,
    TravelFlowSummaryComponent,
    UploadFileComponent,
    ValuableObjectsFlowSummaryComponent,
    VehicleFlowSummaryComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IconsModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatChipsModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    NgbTooltipModule,
    NgxIntlTelInputModule,
    NgxOtpInputModule,
    PdfViewerModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    AccountCheckComponent,
    AccountCheckV2Component,
    AlternateCollapsiblePanelComponent,
    AnimatedBannerComponent,
    BooleanChoiceComponent,
    BuildingFlowSummaryComponent,
    CalendlyInlineEmbedComponent,
    CalendlyPopupEmbedComponent,
    CardComponent,
    CentralisationSummaryComponent,
    ClaimSummaryComponent,
    CollapsibleTabComponent,
    CompanyScrollComponent,
    ContactModalComponent,
    ContractSignatureComponent,
    ContractSignatureV2Component,
    ContractSignatureV3Component,
    CredibilitySectionComponent,
    CurrentInsurerComponent,
    CustomDateComponent,
    DateLimitDirective,
    DoctorComponent,
    DocumentUploadFormComponent,
    DocumentUploadFormV2Component,
    DrawerModalComponent,
    EndScreenV2Component,
    FamilyMemberSelectComponent,
    FamilyMemberSelectV2Component,
    FilterSubmitButtonsComponent,
    FooterComponent,
    FormatCurrencyPipe,
    FormButtonsComponent,
    FormWrapperComponent,
    FormWrapperV2Component,
    GoogleAutocompleteComponent,
    HealthInfoComponent,
    HelpTextComponent,
    HelpTextV2Component,
    HorizontalSeparatorComponent,
    IconsModule,
    InfoDisplayerComponent,
    InfoTooltipComponent,
    InsurancesListComponent,
    KeysPipe,
    LanguageSwitcherComponent,
    LinearStepperComponent,
    ListAdvantagesComponent,
    LoadingOffersOfflineScreenComponent,
    LoadingOffersScreenComponent,
    LoadingScreenComponent,
    LoginComponent,
    LoginV2Component,
    LtyAdviceComponent,
    MatChipsModule,
    MultiStepperComponent,
    NumberFlowComponent,
    NumberInputComponent,
    NumberInputV2Component,
    OffersSearchComponent,
    OffersSearchV2Component,
    OffersViewComponent,
    OtpVerifyComponent,
    PasswordCriteriaComponent,
    PdfViewerModalComponent,
    PersonalInfoComponent,
    PoliciesComponent,
    ProgressBarComponent,
    RegisterComponent,
    RegisterV2Component,
    SelectComponent,
    SelectV2Component,
    SharedCancellationComponent,
    SharedCancellationV2Component,
    SignIconComponent,
    SimpleDocumentUploadComponent,
    SliderComponent,
    SliderV2Component,
    SpinnerComponent,
    StackedCardsComponent,
    StepperComponent,
    ToggleComponent,
    TopBarComponent,
    UploadFileComponent,
    ValuableObjectsFlowSummaryComponent,
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useFactory: getBaseHref,
      deps: [PlatformLocation],
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UiKitModule {}
